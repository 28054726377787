import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

import styles from './sidebar.module.css'

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query PageQuery {
          allContentfulTag(sort: { fields: [publishDate], order: DESC }) {
            edges {
              node {
                name
                slug
              }
            }
          }
        }
      `}
      render={data => {
        const tags = data.allContentfulTag.edges

        return (
          <div className={styles.sidebar}>
            <h2 className={styles.headline}>タグ一覧</h2>
            <ul className={styles.tagList}>
              {tags.map(({ node }) => {
                return (
                  <li className={styles.tag} key={node.name}>
                    <Link to={`/tags/${node.slug}`} className={styles.tagText}>{node.name}</Link>
                  </li>
                )
              })}
            </ul>
          </div>
        )
      }}
    />
  )
} 