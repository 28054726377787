import React, { useState } from 'react'

import styles from './words-search.module.css'

export default () => {
  const [searchWords, setSearchWords] = useState('');

  function sendWords(e) {
    e.preventDefault()
    window.location.href = `/words/${searchWords}`
  }

  function setSearchWord(e) {
    const words = e.target.value.split(/[\u{20}\u{3000}]/u).filter(word => word).join('+')
    setSearchWords(words)
  }

  return (
    <>
      <form className={styles.searchForm} onSubmit={sendWords}>
        <input type="text" className={styles.searchField} onChange={setSearchWord} />
        <button type="submit" className={styles.searchBtn}>検索</button>
      </form>
    </>
  )
} 