import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import TagList from './tag-list.js'
import Image from './image'

import styles from './article-preview.module.css'

export default (({article}) => {
  return (
    <div className={styles.preview}>
      <Link to={`/entries/${article.slug}`}>
        <Image imageName={`${article.slug}.png`} alt={`${article.title}`} />
      </Link>
      <div className={styles.previewDesc}>
        <TagList tags={article.tags}/>
        <h3 className={styles.previewTitle}>
          <Link to={`/entries/${article.slug}`}>
            {article.title}
          </Link>
        </h3>
        <small>{moment(article.publishDate).local().format('YYYY/MM/DD HH:mm')}</small>
      </div>
    </div>
  )
})
