import React from "react"
import { Helmet } from "react-helmet"
import get from 'lodash/get'

function Meta({site}) {
  const metaDescription = get(site, 'siteMetadata.description')
  const metaTitle = `${site.siteMetadata.title} 〜 ${site.siteMetadata.tagline} 〜`
  const host = process.env.HOST || site.siteMetadata.host
  const protcol = process.env.PROTCOL || 'https'
  const ogpImgUrl = `${protcol}://${host}/images/ogp_image.jpg`

  return (
    <Helmet
      htmlAttributes={{
        lang: 'ja',
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogpImgUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `google-site-verification`,
          content: `jogpkvs9se7oV0oQWPcpXDARSKy1u5rKTMKd1ai-Cm4`,
        },
      ]}
    />
  )
}

export default Meta