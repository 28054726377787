import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import Meta from '../components/meta'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import Sidebar from '../components/sidebar'
import WordsSearch from '../components/words-search'
import styles from './common.module.css'

class RootIndex extends React.Component {
  render() {
    const site = get(this, 'props.data.site')
    const posts = get(this, 'props.data.posts.edges')
    return (
      <Layout location={this.props.location}>
        <div className={styles.heroContentsWrap}>
          <Meta
            site={site}
          />
          <div className={styles.hero}>
            <div className={styles.heroContents}>
              <h3 className={styles.heroHeadline}>{site.siteMetadata.title}</h3>
              <p className={styles.heroTitle}>{site.siteMetadata.tagline}</p>
            </div>
          </div>
          <div className="wrapper">
            <div className="articlesContainer">
              <div>
                <h2 className="section-headline">新着コンテンツ</h2>
                <WordsSearch />
                <ul className="article-list">
                  {posts.map(({ node }) => {
                    return (
                      <li key={node.slug} className={node.slug}>
                        <ArticlePreview article={node} />
                      </li>
                    )
                  })}
                </ul>
                <div className={styles.moreContents}>
                  <Link to="/entries/">もっと見る...</Link>
                </div>
              </div>
              <Sidebar />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        tagline
        author
        host
        description
      }
    }
    posts: allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }, limit: 9) {
      edges {
        node {
          title
          slug
          publishDate
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid
            }
          }
          body {
            childMarkdownRemark {
              html
            }
          }
          tags {
            name
            slug
          }
        }
      }
    }
  }
`
